import {postFetchCall, makeFetchCall} from './BaseService';
import config from '../config';

export const fetchNote = async (accessToken, noteId) => {
    const url = config.notes.API_HOST + "/note/" + noteId;
    const response = await makeFetchCall(url, accessToken);
    const responseJson = await response.json();
    console.log("Fetched notes.");
    return responseJson;
}
export const updateNote = async (accessToken, data) => {
    console.log("Inside updateNote function.");
    const url = config.notes.API_HOST + "/note/" + data.noteId;
    const response = await postFetchCall(url, "PUT", accessToken, data.note);
    const responseJson = await response.json();
    return responseJson;
}

export const saveNote = async (accessToken, data) => {
    console.log("Inside saveNote function.");
    const url = config.notes.API_HOST + "/notes";
    const response = await postFetchCall(url, "POST", accessToken, data);
    const responseJson = await response.json();
    return responseJson;
}

export const fetchNotes = async (accessToken) => {
    const url = config.notes.API_HOST + "/notes";
    const response = await makeFetchCall(url, accessToken);
    const responseJson = await response.json();
    console.log("Fetched notes.");
    return responseJson;
}

export const fetchSearchedNotes = async (accessToken, searchTerm) => {
    const url = config.notes.API_HOST + "/notes/search?query=" + searchTerm;
    const response = await makeFetchCall(url, accessToken);
    const responseJson = await response.json();
    console.log("Fetched searched notes.");
    return responseJson;
}
