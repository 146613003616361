import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { A, navigate } from 'hookrouter';
import { Dropdown, ButtonGroup, Button, Form, CardGroup, Card, Container } from 'react-bootstrap';
import { MySpinner } from 'appshome-react';
import { fetchSearchedNotes, fetchNotes } from '../services/NotesService';
import useInputFocus from 'appshome-react/dist/useInputFocus';
import MdSearch from 'react-ionicons/lib/MdSearch';
import Linkify from 'react-linkify';
import { AudioButton } from './NoteForm';

export default function ListNotes(props) {
    const pageSize = 10;
    const [notes, setNotes] = useState({ isLoading: true, list: [], displayedPages: 1, inSearchMode: false });
    const [previousList, setPreviousList] = useState([]);
    const accessToken = props.user.access_token;
    useEffect(() => {
        if (accessToken) {
            fetchNotes(accessToken).then(response => {
                if (Array.isArray(response)) {
                    setNotes(notes => ({ ...notes, isLoading: false, list: response }));
                } else {
                    setNotes(notes => ({ ...notes, isLoading: false, list: [] }));
                }
            });
        }
    }, [accessToken]);

    const searchNotes = (searchTerm) => {
        if (accessToken) {
            fetchSearchedNotes(accessToken, searchTerm).then(response => {
                console.log("Fetched notes: ");
                setPreviousList(notes.list);
                if (Array.isArray(response)) {
                    setNotes({ ...notes, isLoading: false, list: response, displayedPages: 1, inSearchMode: true });
                } else {
                    setNotes({ ...notes, isLoading: false, list: [], displayedPages: 1, inSearchMode: true });
                }
            });
        }
    }
    if (notes.isLoading) {
        return (<MySpinner />);
    } else {
        const increamentPage = () => setNotes({ ...notes, displayedPages: notes.displayedPages + 1 });
        const decreamentPage = () => setNotes({ ...notes, displayedPages: notes.displayedPages - 1 });
        const goBack = () => setNotes({ ...notes, list: previousList, inSearchMode: false, displayedPages: 1 })
        return (
            <Container>
                <NotesActions searchNotes={searchNotes} />
                <div className="mt-2 ml-5 mr-2">
                    {notes.list.slice(0, notes.displayedPages * pageSize).map((note, i) => <NoteItem noteItem={note} key={i} />)}
                </div>
                <ButtonGroup>
                    {notes.inSearchMode ? <Button variant="secondary" onClick={goBack}>Back</Button> : null}
                    {notes.displayedPages > 1 ? <Button variant="info" onClick={decreamentPage}>Less</Button> : null}
                    {notes.list.length > (pageSize * notes.displayedPages) ? <Button variant="primary" onClick={increamentPage}>More</Button> : null}
                </ButtonGroup>
            </Container>
        );
    }
}
function NoteItem(props) {
    const note = props.noteItem;
    const mode = "voice" === props.noteItem.note.mode ? "voice" : "text";
    return (
        <Card className="mr-3 mt-3" style={{ backgroundColor: '#F1F1F1' }}>
            <Card.Header variant="dark">
                <h2 className="h5"><a style={{ color: '#212529' }} onClick={(e) => {
                    e.preventDefault();
                    navigate("/edit/" + note.id, false, { "mode": mode }, false);
                }}>{note.note.title}</a></h2>
            </Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <DisplayNote note={note} />
                </Card.Text>
            </Card.Body>
            <Card.Footer className="justify-content-center">
                <p>{note.updated_at}</p>
            </Card.Footer>
        </Card>
    );
}
function NotesActions(props) {
    const searchRef = useInputFocus();
    const [searchTerm, setSearchTerm] = useState("");
    const handleSearch = () => {
        console.log("Inside search.");
        props.searchNotes(searchTerm);
    };
    return (
        <div className="d-flex  mt-2" style={{ height: 70 }}>
            <div className="d-inline-flex" style={{ width: '80%' }}>
                <Form.Group controlId="notesForm.search" style={{ width: '90%', height: 45 }} className="mt-2">
                    <Form.Control type="text" placeholder="Search term" onChange={(e) => setSearchTerm(e.target.value)} ref={searchRef} />
                </Form.Group>
                <Button variant="light" className="btn btn-info mt-2" style={{ height: 45 }} onClick={handleSearch}>
                    <MdSearch style={{ fontSize: 12 }} />
                </Button>
            </div>
            <div className="mt-2 ml-5" style={{ height: 45, width: '20%' }}>
                {/* <A className="w-100 btn btn-info" href="/create">Create</A> */}
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="w-100">
                        Create
  </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                        <Dropdown.Item href="/create?mode=text">Text</Dropdown.Item>
                        <Dropdown.Item href="/create?mode=voice">Voice</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}
function DisplayNote(props) {
    const playAudio = () => {
        const audioBlob = b64toBlob(props.note.note.note);
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.play();
    }
    if ("voice" === props.note.note.mode) {
        return (
            <AudioButton onClick={playAudio} text="Play" />
        );
    } else {
        return (
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {props.note.note.note}
            </ReactMarkdown>
            // <Linkify properties={{ target: '_blank' }}>
            //     <pre>{props.note.note.note}</pre>
            // </Linkify>
            );
    }
}
function b64toBlob(dataURI) {

    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
}