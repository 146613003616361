import React from 'react';
import config from '../config';

export default function Home(props) {
    return (
        <div className="jumbotron  d-flex  h-100 justify-content-center">
            <div className="col-md-4 col-xs-12 justify-content-center align-items-center">
                <div className="my-5 d-flex justify-content-center">
                    <h1>NOTES</h1>
                </div>
                <div className="d-flex justify-content-center">
                    <p className="lead">
                        A simple web-based notes application.<br />
                    Works on all the modern browsers.<br />
                    Very easy and user friendly.<br />
                    Tag  your notes.<br />
                    Search based on tags.<br />
                    Predefined filters.<br />
                    </p>
                </div>
                <h2>Already an user?</h2>
                <button id="signin" className="btn btn-block btn-primary btn-lg"
                    onClick={() => window.location.href = config.AUTH_LOGIN_URL + "?redirect_url=" + window.location.href}>
                    Sign in
                </button>
                <h2>Don't have an account?</h2>
                <button className="btn btn-lg btn-info btn-block" onClick={() => window.location.href = config.AUTH_REGISTER_URL + "?redirect_url=" + window.location.href} >
                    Sign up
                    </button>
            </div>
        </div>
    );
}
