import React, { useState, useEffect } from 'react';
import {recordAudio } from '../services/Audio';

export function useAudio() {
    const [recorderInitialized, setRecorderInitialized] = useState(false);
    const [recorder, setRecorder] = useState(null);
    useEffect(() => {
        (async() => {
            if (!recorderInitialized) {
            const r = await recordAudio();
            setRecorder(r);
            console.log("Recorder initialized");
            setRecorderInitialized(true);
            }
        })();
    }, []);
    return [recorderInitialized, recorder];
}