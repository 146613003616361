import React from 'react';
import Home from './screens/Home';
import ListNotes from './components/ListNotes';
import CreateNote from './components/CreateNote';
import EditNote from './components/EditNote';

const routes = {
    "/": () => (user) => user ? <ListNotes user={user} /> : <Home />,
    "/create": () => (user) => <CreateNote user={user} />,
    "/edit/:noteId": ({noteId}) => (user) => <EditNote user={user} noteId={noteId} />
}

export default routes;