const config = {
    SESSION_JS_URL: process.env.REACT_APP_SESSION_JS_URL,
    AUTH_LOGIN_URL: process.env.REACT_APP_AUTH_LOGIN_URL,
    AUTH_LOGOUT_URL: process.env.REACT_APP_AUTH_LOGOUT_URL,
    AUTH_REGISTER_URL: process.env.REACT_APP_AUTH_REGISTER_URL,
    notes: {
        API_HOST: process.env.REACT_APP_NOTES_API_HOST,
        CLIENT_ID: 3,
    }
}
export default config;