import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import {saveNote} from '../services/NotesService';
import {useQueryParams, navigate} from 'hookrouter';
import {NoteForm, Actions, AudioButton, NoteTitle, NoteData} from './NoteForm';
import {blobToBase64, recordAudio } from '../services/Audio';
import {useAudio} from '../hooks/UseAudio';

export default function CreateNote(props) {
    const [title, setTitle] = useState("");
    const [note, setNote] = useState("");
    const accessToken = props.user.access_token;
    const [queryParams] = useQueryParams();

    const handleSave = () => {
        saveNote(accessToken, {'title': title, 'note': note, 'mode': queryParams.mode}).then(res => {
            navigate("/");
        })
    }
    if (queryParams.mode === "voice") {
        return (
            <VoiceNote setTitle={setTitle} handleSave={handleSave} setNote={setNote} />
        )
    } else {
        return (
            <TextNote setTitle={setTitle} setNote={setNote} handleSave={handleSave} />
        )
    }
}
function TextNote(props) {
    return (
        <NoteForm title="Create note" onSubmit={props.handleSave}>
            <NoteTitle setTitle={props.setTitle} />
            <NoteData setNote={props.setNote} />
            <Actions saveButtonText = "Create" />
        </NoteForm>
    );
}
function VoiceNote(props) {
    const [recorderInitialized, recorder] = useAudio();
    const [recordingStarted, setRecordingStarted] = useState(false);

    const handleRecording = async() => {
        if (!recorderInitialized) {
            return;
        }
        if (!recordingStarted) {
            recorder.start();
            setRecordingStarted(true);
        } else {
            const audio = await recorder.stop();
            setRecordingStarted(false);
            const blobText = await blobToBase64(audio.audioBlob);
            props.setNote(blobText);
            audio.play();
        }
    }

    return (
        <NoteForm title="Create voice note" onSubmit={props.handleSave}>
            <NoteTitle setTitle={props.setTitle} />
            <Form.Group style={{textAlign: 'center'}}>
                <p>Click start for recording:</p>
                <AudioButton 
                    onClick = {handleRecording}
                    variant={recordingStarted ? "danger" : "success"}
                    text = {recordingStarted ? "Stop & play" : "Start"} />
            </Form.Group>
            <Actions  saveButtonText = "Create" />
        </NoteForm>
    );
}
