import React from 'react';
import routes from '../router';
import {useRoutes } from 'hookrouter';
import {Header, NotFound } from 'appshome-react';

export default function Dashboard(props) {
    const routeResult = useRoutes(routes);
    return (
        <div className="h-100 flex">
            <Header navItems={[]} isAuthenticated={true}>
            <h1 className="h3">Notes</h1>
            </Header>
            {routeResult(props.user) || <NotFound />}
        </div>
    )
}
